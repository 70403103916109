const preUrl = process.env.NODE_ENV === 'development' ? 'https://test-image.qwqwea.com' : 'https://erkshjfs-image.qwqwea.com'

export function gradeFilter (val) {
  switch (val) {
    case -1:
      return '测试组'
    case -2:
      return '访客'
    case 1:
      return 'VIP1'
    case 2:
      return 'VIP2'
    case 3:
      return 'VIP3'
    case 4:
      return 'VIP4'
    case 5:
      return 'VIP5'
    case 6:
      return 'VIP6'
    case 7:
      return 'VIP7'
    case 8:
      return 'VIP8'
    case 9:
      return 'VIP9'
    case 10:
      return 'VIP10'
    case 100:
      return '管理'
  }
}

export function imgHost (val) {
  if (!val) return
  return preUrl + val
}

export function imgCommon (val) {
  if (!val) return ''
  return `${preUrl}/system/common/` + val
}
export function imgLive (val) {
  if (!val) return ''
  return `${preUrl}/system/uu/` + val
}
export function imgAvator (val) {
  if (!val) return ''
  return `${preUrl}` + val
}
export function saveMoney (val) {
  return val
}
export function twoMoney (val) {
  return `¥${val}元`
}
export function hallImgHost (v) {
  if (v) return v.indexOf('group') > -1 ? `${preUrl}/${v}` : `${preUrl}/${v}`
}
export function LocalImgAvator (val) {
  if (!val) return ''
  return `${preUrl}/system/common/headimg` + val
}
